<template>
    <div>
        <table-catalog-domain-regs/>
    </div>
</template>

<script>
import TableCatalogDomainRegs from '@/components/catalog/domain_regs/table.vue'
export default {
  components: { TableCatalogDomainRegs },
    data () {
        return {
           
        }
    }
    
}
</script>